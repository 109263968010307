<template>
  <div class="app-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.app-container {
  min-height: 100vh;
  overflow: auto;
}
</style>
