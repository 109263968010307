import Vue from "vue";
import VueRouter from "vue-router";
import policeNavigation from "../components/PoliceNavigation.vue";
import home from "../components/Home.vue";
import policePlot from "../components/PolicePlot.vue";
import campus from "../components/Campus.vue";

//调用
Vue.use(VueRouter)

//创建路由的实例对象
export const constantRoutes = [
    {
        path: '/',
        redirect: '/home' // 从根路径重定向到 /home
    },
    {
        path:'/home',
        component: home
    },
    {
        path:'/policeNavigation',
        component: policeNavigation
    },
    {
        path:'/policePlot',
        component: policePlot
    },
    {
        path:'/campus',
        component: campus
    },

]

//向外共享路由实例对象
export default new VueRouter({
    mode: 'history', // 去掉url中的#
    routes: constantRoutes,
    scrollBehavior() {
        // return to top position on page refresh
        return { x: 0, y: 0 }
    },
})