<template>
  <HelloWorld>
    <TopBanner :bannerUrl="contentList[contentId-1].bannerUrl"></TopBanner>
    <div class="common-bg police-plot-main">
      <!--需求分析-->
      <div class="common-box" v-if="contentList[contentId-1].requirement">
        <div class="common-box-title">需求分析</div>
        <div class="common-box-content" v-html="contentList[contentId-1].requirement">
        </div>
      </div>
      <!--场景分析-->
      <div class="common-box">
        <div class="common-box-title">场景分析</div>
        <div class="common-box-content">
          <img alt="" :src="contentList[contentId-1].mapUrl">
        </div>
      </div>
      <!--解决方案-->
      <div class="common-box">
        <div class="common-box-title">解决方案</div>
        <div class="common-box-content">
          <img alt="" :src="contentList[contentId-1].solutionUrl">
        </div>
      </div>
      <!--落地案例-->
      <div class="common-box" v-if="contentList[contentId-1].projectIncome">
        <div class="common-box-title">落地案例</div>
        <div class="common-box-content">
          <div class="item-box">
            <div class="item-box-title">
              项目收益
            </div>
            <div class="item-box-content">
              {{ contentList[contentId-1].projectIncome }}
            </div>
          </div>
          <div class="item-box">
            <div class="item-box-title">
              关键点突破
            </div>
            <div class="item-box-content" v-html="contentList[contentId-1].keyPoint"></div>
          </div>
        </div>
      </div>
      <div class="btn back-btn" @click="goBack">返 回</div>
    </div>
  </HelloWorld>

</template>

<script>

import {defineComponent} from "vue";
import HelloWorld from "@/components/HelloWorld.vue";
import TopBanner from "@/components/TopBanner.vue";
import bannerUrl1 from '@/assets/police/policePlot-banner.jpg';
import mapUrl1 from '@/assets/police/policePlot-img1.jpg';
import solutionUrl1 from '@/assets/police/policePlot-img2.jpg';
import bannerUrl2 from '@/assets/police/policeVillage-banner.jpg';
import mapUrl2 from '@/assets/police/policeVillage-img1.jpg';
import solutionUrl2 from '@/assets/police/policeVillage-img2.jpg';
import bannerUrl3 from '@/assets/police/policeRecreation-banner.jpg';
import mapUrl3 from '@/assets/police/policeRecreation-img1.jpg';
import solutionUrl3 from '@/assets/police/policeRecreation-img2.jpg';
//大数据
import bannerUrl4 from '@/assets/police/bigData-banner.jpg';
import mapUrl4 from '@/assets/police/bigData-img1.jpg';
import solutionUrl4 from '@/assets/police/bigData-img2.jpg';
//基层智治
import bannerUrl5 from '@/assets/police/basicLevel-banner.jpg';
import mapUrl5 from '@/assets/police/basicLevel-img1.jpg';
import solutionUrl5 from '@/assets/police/basicLevel-img2.jpg';

//市监局
import bannerUrl6 from '@/assets/police/openKitchen-banner.jpg';
import mapUrl6 from '@/assets/police/openKitchen-img1.jpg';
import solutionUrl6 from '@/assets/police/openKitchen-img2.jpg';
import bannerUrl7 from '@/assets/police/shop-banner.jpg';
import mapUrl7 from '@/assets/police/shop-img1.jpg';
import solutionUrl7 from '@/assets/police/shop-img2.jpg';

//教委
import bannerUrl8 from '@/assets/police/educationInstitution-banner.jpg';
import bannerUrl9 from '@/assets/police/school-banner.jpg';

//医保局
import bannerUrl10 from '@/assets/police/medicalInsurance-banner.jpg';
import mapUrl10 from '@/assets/police/medicalInsurance-img1.jpg';
import solutionUrl10 from '@/assets/police/medicalInsurance-img2.jpg';

//文旅委
import bannerUrl11 from '@/assets/police/culturalTravel-banner.jpg';

//住建委-城市内涝监控
import bannerUrl12 from '@/assets/police/waterlogging-banner.jpg';
//住建委-工地监工
import bannerUrl13 from '@/assets/police/siteSupervisor-banner.jpg';
import mapUrl13 from '@/assets/police/siteSupervisor-img1.jpg';
import solutionUrl13 from '@/assets/police/siteSupervisor-img2.jpg';
//住建委-公租房
import bannerUrl14 from '@/assets/police/publicHousing-banner.jpg';
import solutionUrl14 from '@/assets/police/publicHousing-img2.jpg';
//民政局-独居老人
import bannerUrl15 from '@/assets/police/liveAlone-banner.jpg';
import mapUrl15 from '@/assets/police/liveAlone-img1.jpg';
import solutionUrl15 from '@/assets/police/liveAlone-img2.jpg';
//民政局-养老院
import bannerUrl16 from '@/assets/police/nursingHome-banner.jpg';

//连锁商品
import bannerUrl17 from '@/assets/police/chainStore-banner.jpg';
import mapUrl17 from '@/assets/police/chainStore-img1.jpg';
import solutionUrl17 from '@/assets/police/chainStore-img2.jpg';

//酒吧
import bannerUrl18 from '@/assets/police/saloon-banner.jpg';

//乡镇政府
import bannerUrl19 from '@/assets/police/township-banner.jpg';

//街社政府
import bannerUrl20 from '@/assets/police/streetShop-banner.jpg';
import solutionUrl20 from '@/assets/police/streetShop-img2.jpg';

//园区
import bannerUrl21 from '@/assets/police/parkArea-banner.jpg';
import mapUrl21 from '@/assets/police/parkArea-img1.jpg';
import solutionUrl21 from '@/assets/police/parkArea-img2.jpg';

export default defineComponent({
  name:'PolicePlot',
  components: {TopBanner, HelloWorld},
  data(){
    return {
      contentList: [
        //公安派出所
        {
          bannerUrl: bannerUrl1,
          requirement:'市公安局高度重视智能安防小区建设，将其纳入工作三年行动计划，并将小区视频监控接入公安平台纳入各区公安考核指标。',
          mapUrl:mapUrl1,
          solutionUrl:solutionUrl1,
          projectIncome: 'XX项目新装58路摄像头+116路AI+116路语音播报+9路音柱，带来13.3万元收入。',
          keyPoint: "举措一：梳理现有街道物业客情，逐一沟通走访。\n举措二：现场演示，体验营销。\n举措三：以点及面，客情复用。"
        },
        {
          bannerUrl: bannerUrl2,
          requirement:'目前存量摄像头终端多，平台多，管理困难，各级公安政府大力推行雪亮乡村，实现对辖区监控监管，通过叠加AI技术，对重点人群及车辆进行管控。',
          mapUrl:mapUrl2,
          solutionUrl:solutionUrl2,
          projectIncome: 'XX分公司签订13个乡政府雪亮乡村项目，全新增共512个点位，整体拉动收入约X万/年。',
          keyPoint: "举措一：公安局提出雪亮乡村建设要求，电信积极承接。\n举措二：产品赋能，乡镇逐个突破。"
        },
        {
          bannerUrl: bannerUrl3,
          requirement:'“九小场所”人员复杂，案情高发 ，存量摄像头终端多，平台多，管理困难。',
          mapUrl:mapUrl3,
          solutionUrl:solutionUrl3,
          projectIncome: 'XX支局借助天翼视联AI合计办理重点场所主推融合22套，存量升级3户（还有15家未上门），摄像头安装66个，上门成功率85%。',
          keyPoint: "举措一：勤走访，了解需求。\n举措二：BC协同，获支持。\n举措三：快交付，提升客户体验。"
        },
        //大数据
        {
          bannerUrl: bannerUrl4,
          requirement:'存量摄像头终端多，平台多，管理困难，全域全行业的视频汇聚有利于大数据局进行精准决策与治理，快速响应和处置突发事件，优化资源配置和提升效率 。',
          mapUrl:mapUrl4,
          solutionUrl:solutionUrl4,
          projectIncome: 'XX项目已接入9.7万路设备，预计将接入超20万路视频点位，合同金额近440万元。',
          keyPoint: "举措一：抓商机，紧抓政策风口，第一时间找到主办单位领导切入。\n举措二：抢先机，3天内完成视频中台和AI中台算法部署，并向客户演示天翼视联平台海量的视频资源及丰富的AI算法，1个月内完成平台建设，交付使用。\n举措三：显优势，针对用户需求制定针对性解决方案，突显电信优势。"
        },
        //基层智治
        {
          bannerUrl: bannerUrl5,
          requirement:'目前基层网格多采用人工登记，手工填报等方式，实时性差，准确率低。街道所辖视频源无法直接推送基层治理指挥中心，导致视频信息不互通，无法快速实现事中处置、事后办结。',
          mapUrl:mapUrl5,
          solutionUrl:solutionUrl5,
          projectIncome: 'xx分公司xx镇收编监控153路，拉动宽带新增4条 ，叠加AI能力。分公司年增收约6.7万',
          keyPoint: "举措一：商机获取，客户经理了解到客户需求后 ，上门拜访介绍演示基层智治产品和智能要素超市AI能力。\n举措二：体现优势，向客户讲解电信已打造的基层街道标杆案例和服务保障；其次强调以月租形式付费可以减少一次性投入。多方位多形式多技术提升基层治理能效，打动客户签约。"
        },
        //市监局
        {
          bannerUrl: bannerUrl6,
          requirement:'规范市场秩序，遏制不合规行为，减少食品安全事故发生的可能性。提高消费者信任和满意度，增加消费者对餐饮行业的信心。',
          mapUrl:mapUrl6,
          solutionUrl:solutionUrl6,
          projectIncome: 'xx分公司7月8日至7月21日营销成功900户明厨亮灶；年增收几十万左右收入。',
          keyPoint: "举措一：签约战略合作，xx分公司与长寿区市场监督管理局签订战略合作协议。\n举措二：客情拜访，对接各街镇监管所介绍模型并演示平台；协同市场监督管理所对辖区内餐饮企业摸排；建立监管所平台账号，实现可视化应用。\n举措三：活动变现，通过市场监管所工作人员带队、电话通知后工作组上门营销攻坚。"
        },
        //市监局
        {
          bannerUrl: bannerUrl7,
          requirement:'市场监管局扩大对餐饮单位公共区域的食品安全监管，并函商运营商共同推进。',
          mapUrl:mapUrl7,
          solutionUrl:solutionUrl7,
          projectIncome: '分公司收编XX餐饮90路摄像头，并新增替换8路监控，年增收近万元；后续持续营销叠加云存、AI能力，预计年增收5千元。',
          keyPoint: "举措一：了解到客户需求后，及时上门拜访介绍演示云眼产品。\n举措二：根据客户心理价位确定前期收编路数。\n举措三：为客户试点收编几路，通过产品能力说服客户签约。"
        },
        //教委
        {
          bannerUrl: bannerUrl8,
          requirement:'教培机构下属多个网点需要统一管理\n家长对孩子在校学习情况有实时监看需求\n国家教育部以及市教委对教培机构有监管需求',
          mapUrl:mapUrl7,
          solutionUrl:solutionUrl7,
          projectIncome: 'xx分公司xx连锁教培机构20路摄像头上云，结合两条千兆宽带，年新增收入xx万元。',
          keyPoint: "举措一：客户经理了解需求后，上门拜访介绍产品。\n举措二：根据客户心理价位提供合适方案。\n举措三：根据客户统一管理下属培训机构需求，进一步营销工作。"
        },
        {
          bannerUrl: bannerUrl9,
          requirement:'学校仍存在监控盲区\n市教委对学校有视频监管需求',
          mapUrl:mapUrl7,
          solutionUrl:solutionUrl7,
          projectIncome: 'xx分公司收编XX幼儿园45路摄像头上云，新增7路摄像头，年新增收入1.9万元。',
          keyPoint: "举措一：支局客户经理及时向客户介绍云眼收编及AI监控产品。\n举措二：迅速搭建演示环境，向客户展示效果。"
        },
        //医保局
        {
          bannerUrl: bannerUrl10,
          requirement:'医保局无法监管辖区所有药店，连锁药店无法实现总、分店同时监管。',
          mapUrl:mapUrl10,
          solutionUrl:solutionUrl10,
          projectIncome: ' XX分公司全市率先打造医保基金监管应用标杆，整体接入1000余路智能监控。',
          keyPoint: "举措一：商机敏锐洞察，持续跟进。\n举措二：BC协同，解决方案命中客户需求。"
        },
        //文旅委
        {
          bannerUrl: bannerUrl11,
          requirement:'景区现有监控年久失修，维修成本高\n景区业主、工作人员需要实时观看视频直播\n危险区域无人值守，需要技术手段助力\n全市3万多家农家乐、民宿，市场增量空间大',
          mapUrl:mapUrl7,
          solutionUrl:solutionUrl7,
          projectIncome: 'xx分公司为XX景区新增替换22路摄像头，提升收入，后续持续营销叠加云存、AI能力。',
          keyPoint: "举措一：登门拜访，及时发现客户客户痛点。\n举措二：重点向客户强调售后优势。\n举措三：为客户展示设备，获得客户认可。"
        },
        //住建委 城市内涝监控
        {
          bannerUrl: bannerUrl12,
          requirement:'2021年《国务院办公厅关于加强城市内涝治理的实施意见》提出“在排水设施关键节点、易涝积水点布设必要的智能化感知终端设备”。\n城市已建点位分散，并未汇聚纳管，且存在监控盲区，新建监控点位需一次性大笔资金，投入困难，维护成本高、设备维护支撑困难。',
          mapUrl:mapUrl7,
          solutionUrl:solutionUrl7,
          projectIncome: 'XX项目收编XX路摄像头，年新增收入XX万元。',
          keyPoint: "举措一：客情穿透，获取客户需求。\n举措二：迅速收编演示摄像头，搭建环境，向客户展示效果。"
        },
        //住建委 工地监工
        {
          bannerUrl: bannerUrl13,
          requirement:'工地施工过程危险系数高，传统监管模式主要依赖人力，手段单一，管理难度较大。',
          mapUrl:mapUrl13,
          solutionUrl:solutionUrl13,
          projectIncome: 'xx分公司改造升级农房区域监控能力，拉动收入约X万/年。',
          keyPoint: "举措一：结合辖区内重要事件（农房建设事故），沟通走访相关部门。\n举措二：了解具体需求后政企部和集成中心沟通方案，再通过安装摄像头+AI给用户做测试体验，让客户对视联网业务直观体验到功能强大。"
        },
        //住建委 公租房
        {
          bannerUrl: bannerUrl14,
          requirement:'租户群体多元化，流动大，安保管理复杂，信息反馈不及时，已建监控点位分散，监控设施老旧失修，并未汇聚纳管，且存在监控盲区。',
          mapUrl:mapUrl1,
          solutionUrl:solutionUrl14,
          projectIncome: 'xx分公司为xx街道收编公租房127路监控，新增20路监控，并新增3条宽带，拉动收入xx万/年。',
          keyPoint: "举措一：梳理现有街道物业客情，逐一沟通走访。\n举措二：现场演示，体验营销。\n举措三：以点及面，客情复用。"
        },
        //民政局-独居老人
        {
          bannerUrl: bannerUrl15,
          requirement:'由于家庭空巢化、小型化趋势的持续，一些老年人在逐渐失去行为能力的过程中面临监护缺失的问题。\n独居老年容易出现突发疾病或遇到意外情况时，无人料理从而错过紧急医疗救治、身后事的情况。\n新建监控点位需一次性大笔资金，投入困难，维护成本高、设备维护支撑困难。',
          mapUrl:mapUrl15,
          solutionUrl:solutionUrl15,
          projectIncome: '',
          keyPoint: ""
        },
        //民政局-养老院
        {
          bannerUrl: bannerUrl16,
          requirement:'养老院较为分散，监管难且没有有效管控手段，部分养老院视频监控设备陈旧，无法完全实现视频监控。\n新建监控点位需一次性大笔资金，投入困难，维护成本高、设备维护支撑困难。',
          mapUrl:mapUrl15,
          solutionUrl:solutionUrl15,
          projectIncome: '联网收编约1000路，拉动DICT项目收入约20万元。',
          keyPoint: "XX分公司良好的客情关系，公司多位领导亲自拜访客户促成合作；以视联网标品为抓手，推进项目落地；因客户点位数量多且分散，长治电信市县联动，突出属地装维的优势，社会三方公司无法承接。"
        },
        //连锁商铺
        {
          bannerUrl: bannerUrl17,
          requirement:'连锁商户老板下属门分店多且分散，有远程观看视频监控需求。重点区域（收银台、货架、库房）需加强监管。',
          mapUrl:mapUrl17,
          solutionUrl:solutionUrl17,
          projectIncome: 'xx分公司为漫格时光超市2个门店新装70路摄像头，年增收约xx万元。',
          keyPoint: "商机获取：客户经理了解到客户需求后，上门拜访介绍演示云眼产品，获取客户心理价位。\n体现优势：向客户讲解电信所具备的完善售后保障(突出设备免费更换政策)；其次强调以月租形式付费可以减少一次性投入。通过上述两点打动客户签约。\n项目签约：客户经理拟定合同完成签约。"
        },
        //酒吧
        {
          bannerUrl: bannerUrl18,
          requirement:'常规的上网实名制等管理手段很难有效监管，网吧自建的视频监控点位往往关键位置（如门口）覆盖不到位，且未接入相关系统进行监管。',
          mapUrl:mapUrl17,
          solutionUrl:solutionUrl17,
          projectIncome: 'xx分公司收编南岸碧佳温泉酒店90路摄像头，并新增替换8路监控，年增收近万元;后续持续营销叠加云存、AI能力，预计年增收5千元。',
          keyPoint: "商机获取：客户经理了解到客户需求后，上门拜访介绍演示云眼产品，获取客户心理价位。\n解决方案：根据客户心理价位确定前期收编90路（不加云存）+新增8路（7天云存）方案。\n项目试点：为客户试点收编5路通过产品能力说服客户签约。\n项目签约：客户经理拟定合同完成签约。"
        },
        //乡镇政府
        {
          bannerUrl: bannerUrl19,
          requirement:'',
          mapUrl:mapUrl2,
          solutionUrl:solutionUrl2,
          projectIncome: 'xx分公司鱼塘区域新增云眼12条，拉动宽带新增12条；河岸区域新增云眼16条，拉动宽带新增16条，分公司年增收约xx万。',
          keyPoint: "梳理辖区内有水域的，且对溺水防控监管有智慧化升级转型需求的乡镇、街道、村政府以及鱼塘主等，让其体验防溺水平台，协同完成水域监控统一接入管理；以政府指导文件为依据，面向政府单位、水塘主开展“水域安全新升级，暑期管理无忧”专项营销活动；分公司统筹部署，农村支局快速推进；支局长客情穿透，勤走访，抓准关键人；受理、交付等重要环节专人盯及时支撑，形成项目日通报机制。"
        },
        //街社政府/物业
        {
          bannerUrl: bannerUrl20,
          requirement:'',
          mapUrl:mapUrl1,
          solutionUrl:solutionUrl20,
          projectIncome: 'xx分公司收编XX物业公司100路监控，拉动收入X万/年',
          keyPoint: "举措一：梳理现有物业客情，逐一沟通走访，在与XX物业管理公司沟通过程中发现其今年有相关硬件设施升级计划，打算对视频监控统一汇聚，于是便对其展示了天翼云眼平台的汇聚功能，对方较为认可，同意先体验上云后改造监控，后续根据使用情况再考虑填加AI功能。\n举措二：通过对客户演示天翼云眼汇聚平台、分级管理等功能，得到客户高度认可，同时用户感知良好，觉得价格合理，售后保障好，性价比高。"
        },
        //园区
        {
          bannerUrl: bannerUrl21,
          requirement:'常规的上网实名制等管理手段很难有效监管，网吧自建的视频监控点位往往关键位置（如门口）覆盖不到位，且未接入相关系统进行监管',
          mapUrl:mapUrl21,
          solutionUrl:solutionUrl21,
          projectIncome: 'xx分公司收编XX交易城监控344路，年增收约2.6万元。',
          keyPoint: "商机获取：客户经理了解到客户需求后，上门拜访介绍演示云眼平台能力，AI功能，获取客户心理价位。\n体现优势：以电信的服务和品质，宽带与摄像头的统一维护，设备的免费更换，体现电信优势和国企的售后保障，以云眼平台的集约能力，可以减少客户人力成本，提高监管效率打动客户签约。"
        },
      ],
      contentId: this.$route.query.id
    }
  },
  mounted() {

  },
  watch: {
    'contentId':{
      handler: function (newVal) {
        this.contentId = newVal
      },
      deep: true
    },
  },
  methods: {
    goBack(){
      // this.$router.back()
      window.history.back()
    }
  }
})
</script>


<style scoped>

</style>