<template>
  <div class="navigation-main">
    <div class="navigation-main-content" @click="JumpDetail">
      <img alt="" :src="navImg">
      <!--箭头-->
      <div class="arrow-list">
        <span class="arrowImg"></span>
        <span class="arrowImg"></span>
        <span class="arrowImg"></span>
      </div>
    </div>
    <p class="main-bg bg1"></p>
    <p class="main-bg bg2"></p>
    <p class="main-bg bg3"></p>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name:'navigationList',
  components: {},
  props: {
    navImg: {
      type: String,
      default: ''
    },
    jumpUrl: {
      type: String,
      default: ''
    }
  },
  data(){
    return {

    }
  },
  mounted() {

  },
  methods: {
    JumpDetail(){
      this.$router.push({path: this.jumpUrl})
    }
  }
})
</script>

<style scoped>
.navigation-main{
  margin-bottom: 50px;
  position: relative;
}
.arrow-list{
  position: absolute;
  right: 60px;
  top: 74px;
  z-index: 7;
}
.arrow-list span{
  display: inline-block;
  width: 40px;
  height: 100px;
  background: url("@/assets/arrowImg.png") no-repeat center;
  background-size: 100%;
  margin-left: -5px;
}
.navigation-main img{
  width: 100%;
}
.navigation-main-content{
  position: relative;
  z-index: 5;
}
.main-bg{
  width: 98%;
  height: 231px;
  left: 1%;
  border-radius: 50px;
  bottom: -30px;
  position: absolute;
  z-index: 4;
}
.bg1{
  background: #bbc1c8;
}
.bg2{
  background: #cccfd2;
  width: 96%;
  left: 2%;
  bottom: -38px;
  z-index: 3;
}
.bg3{
  background: #dadada;
  width: 94%;
  left: 3%;
  bottom: -46px;
  z-index: 2;
  box-shadow: 0 0 5px 5px rgba(187,193,200,0.1);
}
.arrowImg{
  -webkit-animation: arrowImg-trans 1s both linear infinite;
}
.arrowImg:nth-child(2){
  -webkit-animation-delay: 0.2s;
}
.arrowImg:nth-child(3){
  -webkit-animation-delay: 0.4s;
}
@keyframes arrowImg-trans {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateX(15px);
    opacity: 1;
  }
  100% {
    transform: translateX(30px);
    opacity: 0;
  }
}
</style>