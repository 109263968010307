<template>
  <HelloWorld>
    <TopBanner :bannerUrl="navList[contentId-1].bannerUrl"></TopBanner>
    <div class="common-bg police-navigation-main" :class="contentId === '4' ? 'police-navigation-main-min' : ''">
      <navigation-list
          v-for="(item,index) in navList[contentId-1].policeNavList"
          :key="index"
          :nav-img="item.img"
          :jumpUrl="item.jumpUrl"
           :class="contentId === '4' ? 'navigation-main-min' : ''"
      >
      </navigation-list>
      <div class="btn-home" :class="contentId === '4' || contentId === '1' ? 'btn-home-position': ''">
        <div class="btn" @click="goHome">返 回 首 页</div>
      </div>

    </div>
  </HelloWorld>

</template>

<script>
import {defineComponent} from "vue";
import TopBanner from "@/components/TopBanner.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import navigationList from "@/components/components/navigationList.vue"

import bannerUrl1 from '@/assets/Publicsecurity-banner.jpg'; // 使用import引入图片
import navImg1 from "@/assets/Publicsecurity-img1.png";
import navImg2 from "@/assets/Publicsecurity-img2.png";
import navImg3 from "@/assets/Publicsecurity-img3.png";

import bannerUrl2 from '@/assets/cityMunicipal-banner.jpg'; // 使用import引入图片
import cityNavImg1 from "@/assets/cityMunicipal-img1.png";
import cityNavImg2 from "@/assets/cityMunicipal-img2.png";

import bannerUrl3 from '@/assets/education-banner.jpg'; // 使用import引入图片
import educationNavImg1 from "@/assets/education-img1.png";
import educationNavImg2 from "@/assets/education-img2.png";

//住建委
import bannerUrl4 from '@/assets/ministryHousing-banner.jpg'; // 使用import引入图片
import ministryHousingNavImg1 from "@/assets/ministryHousing-img1.png";
import ministryHousingNavImg2 from "@/assets/ministryHousing-img2.png";
import ministryHousingNavImg3 from "@/assets/ministryHousing-img3.png";
import ministryHousingNavImg4 from "@/assets/ministryHousing-img4.png";

//民政局
import bannerUrl5 from '@/assets/civilAffairsBureau-banner.jpg'; // 使用import引入图片
import civilNavImg1 from "@/assets/civilAffairsBureau-img1.png";
import civilNavImg2 from "@/assets/civilAffairsBureau-img2.png";

export default defineComponent({
  name:'policeNavigation',
  components: {
    HelloWorld,
    TopBanner,
    navigationList
  },
  data(){
    return {
      navList:[
        //公安聚合页列表
        {
          bannerUrl: bannerUrl1,
          policeNavList: [
            {img: navImg1, jumpUrl:'/policePlot?id=1'},
            {img: navImg2, jumpUrl:'/policePlot?id=2'},
            {img: navImg3, jumpUrl:'/policePlot?id=3'},
          ],
        },
        //市监局聚合页列表
        {
          bannerUrl: bannerUrl2,
          policeNavList: [
            {img: cityNavImg1, jumpUrl:'/policePlot?id=6'},
            {img: cityNavImg2, jumpUrl:'/policePlot?id=7'},
          ],
        },
        //教委聚合页列表
        {
          bannerUrl: bannerUrl3,
          policeNavList: [
            {img: educationNavImg1, jumpUrl:'/policePlot?id=8'},
            {img: educationNavImg2, jumpUrl:'/policePlot?id=9'},
          ],
        },
        //住建委聚合页列表
        {
          bannerUrl: bannerUrl4,
          policeNavList: [
            {img: ministryHousingNavImg1, jumpUrl:'/policePlot?id=12'},
            {img: ministryHousingNavImg2, jumpUrl:'/policePlot?id=1'},
            {img: ministryHousingNavImg3, jumpUrl:'/policePlot?id=13'},
            {img: ministryHousingNavImg4, jumpUrl:'/policePlot?id=14'},
          ],
        },
        //住建委聚合页列表
        {
          bannerUrl: bannerUrl5,
          policeNavList: [
            {img: civilNavImg1, jumpUrl:'/policePlot?id=15'},
            {img: civilNavImg2, jumpUrl:'/policePlot?id=16'},
          ],
        }
      ],

      contentId: this.$route.query.id
    }
  },
  mounted() {

  },
  watch: {
    'contentId':{
      handler: function (newVal) {
        this.contentId = newVal
      },
      deep: true
    },
  },
  methods: {
    goHome(){
      this.$router.push({path: '/home'})
    }
  }
})
</script>

<style scoped>
.police-navigation-main{
  padding-top: 80px;
}
.police-navigation-main-min{
  padding-top: 30px !important;
}
.btn-home{
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0;
}
.btn-home-position{
  position: relative !important;
  margin-top: 60px;
}
</style>
<style>
.navigation-main-min{
  margin-bottom: 40px !important;
}
</style>