<template>
  <div class="home-banner" :style="'background: url('+bannerUrl+') no-repeat center;background-size: 100%;' ">
  </div>
</template>
<script>
export default {
  props: {
    bannerUrl:{
      type: String,
    }
  }
}
</script>
<style scoped>
.home-banner{
  width: 100%;
  height: 288px;
  position: relative;
}
</style>