<template>
  <div id="app">
<!--    <img alt="Vue logo" src="./assets/logo.png">-->
    <router-view>
      <Home/>
    </router-view>
  </div>
</template>

<script>
import Home from "@/components/Home.vue";
export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
#app {
  font-family: "黑体",Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 28px;
}
body{
  padding: 0;
  margin: 0;
}
.common-bg{
  background: #e0e3ff;
  border-top-right-radius: 60px;
  border-top-left-radius: 60px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  margin-top: -50px;
  min-height: calc( 100vh - 238px);
}
.common-box{
  background: #ffffff;
  margin: 10px 10px 30px 10px;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  border-radius: 15px;
  position: relative;
  text-align: left;
  color: #666666;
  box-shadow: 0 0 5px 5px rgba(150,150,150,0.1);
}
.common-box-title{
  background: #c7d1e0;
  color: #2067b6;
  font-size: 30px;
  font-weight: bold;
  padding: 10px 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: inline-block;
  margin-left: 10px;
}
.common-box-content{
  padding: 15px 0;
  line-height: 42px;
  font-size: 26px;
  white-space: pre-wrap;

}
.common-box-content img{
  width: 100%;
}
.item-box{
  position: relative;
  border: 1px solid #abc6e4;
  border-radius: 20px;
  margin: 40px 0;
  padding: 45px 10px 20px 15px;
  white-space: pre-wrap;
}
.item-box-title{
  border: 1px solid #abc6e4;
  border-radius: 20px;
  background: #d5dcff;
  font-size: 26px;
  padding: 5px 25px;
  box-sizing: border-box;
  display: inline-block;
  position: absolute;
  top: -28px;
  color: #666666;
}
.btn{
  background: #d5dcff;
  border-radius: 20px;
  padding: 15px 50px;
  display: inline-block;
  margin: 20px auto;
  color: #1f66b6;
  border: 2px solid #1f66b6;
}

</style>
