<template>
  <HelloWorld>
    <TopBanner :bannerUrl="bannerUrl"></TopBanner>
    <div class="common-bg police-plot-main">
      <!--场景一-->
      <div class="common-box">
        <div class="common-box-title">场景一：视频汇聚、监控补盲</div>
        <div class="common-box-content">
          <div class="campus-main">
            <div class="common-box-content-left">校园监控存在隐蔽角落、盲区，收编及补点，实 现视频汇聚并统一纳管。</div>
            <div class="common-box-content-right">
              <img alt="" src="@/assets/campus/campus-img1.jpg">
            </div>
          </div>
        </div>
      </div>
      <!--解决方案-->
      <div class="common-box">
        <div class="common-box-title">解决方案</div>
        <div class="common-box-content">
          <img alt="" src="@/assets/campus/campus-img2.jpg">
        </div>
      </div>
      <!--场景二-->
      <div class="common-box">
        <div class="common-box-title">场景二：关键区域警戒</div>
        <div class="common-box-content">
          <div class="campus-main">
            <div class="common-box-content-left">楼顶天台、校园角落等区域入侵报警，人员可进行快速处置。</div>
            <div class="common-box-content-right">
              <img alt="" src="@/assets/campus/campus-img3-top.jpg">
            </div>
          </div>
          <div class="campus-main">
            <div class="common-box-content-left">出现霸凌行为时，学生可通过一键告警实时警报，并联动校园及派出所监管人员及时处置。</div>
            <div class="common-box-content-right">
              <img alt="" src="@/assets/campus/campus-img3-bottom.jpg">
            </div>
          </div>
        </div>
      </div>
      <!--解决方案-->
      <div class="common-box">
        <div class="common-box-title">解决方案</div>
        <div class="common-box-content">
          <img alt="" src="@/assets/campus/campus-img4.jpg">
        </div>
      </div>
      <!--场景三-->
      <div class="common-box">
        <div class="common-box-title">场景三：学生行为督导</div>
        <div class="common-box-content">
          <div class="campus-main">
            <div class="common-box-content-left" style="margin-top: 0">识别疑似打架斗殴、霸凌呼救、霸凌侵犯语言等，及时预警和及早干预；玩手机等行为监控，及时管理。</div>
            <div class="common-box-content-right">
              <img alt="" src="@/assets/campus/campus-img5-top.jpg">
            </div>
          </div>
          <div class="campus-main">
            <div class="common-box-content-left" style="margin-top: 5px">安全巡检，通过实时语音双向对讲及时制止可疑行为，隐患上报、处置、验收全闭环。</div>
            <div class="common-box-content-right">
              <img alt="" src="@/assets/campus/campus-img5-bottom.jpg">
            </div>
          </div>
        </div>
      </div>
      <!--解决方案-->
      <div class="common-box">
        <div class="common-box-title">解决方案</div>
        <div class="common-box-content">
          <img alt="" src="@/assets/campus/campus-img6.jpg">
        </div>
      </div>
      <!--场景四-->
      <div class="common-box">
        <div class="common-box-title">场景四：霸凌行为告警呼救</div>
        <div class="text-wrap">
          <img alt="" src="@/assets/campus/campus-img7.jpg" class="align-left">
          <p>通过集成拾音设备接入、音频存储、音频AI分析、防霸凌功能等应用，应用于校园内言语欺凌，嘲笑、辱骂，威胁恐吓等霸凌事件的隐密场景，解决宿舍、洗手间等隐私区域监控盲区痛点。</p>
        </div>
      </div>
      <!--解决方案-->
      <div class="common-box">
        <div class="common-box-title">解决方案</div>
        <div class="common-box-content">
          <img alt="" src="@/assets/campus/campus-img8.jpg">
        </div>
      </div>
      <div class="btn back-btn" @click="goBack">返 回</div>
    </div>
  </HelloWorld>

</template>

<script>

import {defineComponent} from "vue";
import HelloWorld from "@/components/HelloWorld.vue";
import TopBanner from "@/components/TopBanner.vue";
import bannerUrl from '@/assets/campus/campus-banner.jpg';
export default defineComponent({
  name: 'campusVue',
  components: {TopBanner, HelloWorld},
  data(){
    return {
      bannerUrl: bannerUrl
    }
  },
  mounted() {

  },
  methods: {
    goBack(){
      this.$router.go(-1)
    }
  }
})
</script>

<style scoped>
.common-box-content{
  padding: 15px 10px;
}
.campus-main{
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}
.common-box-content-left{
  margin-top: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  text-align: justify;
}
.common-box-content-right img{
  width: 324px;
}
.align-left {
  float: right;
  width: 324px;
  margin-right: 15px; /* 文字和图片之间的间距 */
}
.text-wrap {
  word-wrap: break-word; /* 当单词长度超过容器宽度时，自动换行 */
  overflow: hidden; /* 清除浮动效果 */
  line-height: 42px;
  font-size: 26px;
  padding: 15px 0;
}
</style>