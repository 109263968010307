<template>
  <HelloWorld>
    <TopBanner :bannerUrl="bannerUrl"></TopBanner>
    <div class="common-bg home-main">
      <!--全域监管-->
      <div class="home-main-div">
        <div class="home-main-title"><p>3类 全域监管</p></div>
        <div class="home-main-content">
          <div class="home-item" @click="goPoliceNavigation(1)">
            <img alt="" src="@/assets/index-icon1.jpg">
            <span>公安、派出所</span>
          </div>
          <div class="home-item" @click="goDetail(4)">
            <img alt="" src="@/assets/index-icon2.png">
            <span>大数据局</span>
          </div>
          <div class="home-item" @click="goDetail(5)">
            <img alt="" src="@/assets/index-icon3.png">
            <span>“141”基层智治</span>
          </div>
        </div>
      </div>
      <!--6类 行业监管-->
      <div class="home-main-div">
        <div class="home-main-title"><p>6类 行业监管</p></div>
        <div class="home-main-content">
          <div class="home-item" @click="goPoliceNavigation(2)">
            <img alt="" src="@/assets/index-icon4.png">
            <span>市监局</span>
          </div>
          <div class="home-item" @click="goPoliceNavigation(3)">
            <img alt="" src="@/assets/index-icon5.png">
            <span>教委</span>
          </div>
          <div class="home-item" @click="goDetail(10)">
            <img alt="" src="@/assets/index-icon6.png">
            <span>医保局</span>
          </div>
          <div class="home-item" @click="goDetail(11)">
            <img alt="" src="@/assets/index-icon7.png">
            <span>文旅委</span>
          </div>
          <div class="home-item" @click="goPoliceNavigation(4)">
            <img alt="" src="@/assets/index-icon8.png">
            <span>住建委</span>
          </div>
          <div class="home-item" @click="goPoliceNavigation(5)">
            <img alt="" src="@/assets/index-icon9.png">
            <span>民政局</span>
          </div>
        </div>
      </div>
      <!--6类 重点G/B客户-->
      <div class="home-main-div">
        <div class="home-main-title"><p>6类 重点G/B客户</p></div>
        <div class="home-main-content">
          <div class="home-item" @click="goCampus">
            <img alt="" src="@/assets/index-icon10.png">
            <span>校园</span>
          </div>
          <div class="home-item" @click="goDetail(17)">
            <img alt="" src="@/assets/index-icon11.png">
            <span>连锁商铺</span>
          </div>
          <div class="home-item" @click="goDetail(18)">
            <img alt="" src="@/assets/index-icon12.png">
            <span>酒店/网吧</span>
          </div>
          <div class="home-item" @click="goDetail(19)">
            <img alt="" src="@/assets/index-icon13.png">
            <span>乡镇政府<br />(数字乡村)</span>
          </div>
          <div class="home-item" @click="goDetail(20)">
            <img alt="" src="@/assets/index-icon14.png">
            <span>街社政府/物业<br />(智慧社区)</span>
          </div>
          <div class="home-item" @click="goDetail(21)">
            <img alt="" src="@/assets/index-icon15.png">
            <span>园区</span>
          </div>
        </div>
      </div>
    </div>
  </HelloWorld>
</template>
<script>
import HelloWorld from "@/components/HelloWorld.vue";
import TopBanner from "@/components/TopBanner.vue";
import bannerUrl from '@/assets/index-banner.png'; // 使用import引入图片
export default {
  name: 'HomeVue',
  components: {
    HelloWorld,
    TopBanner
  },
  props: {
    msg: String
  },
  data(){
    return {
      bannerUrl: bannerUrl
    }
  },
  mounted() {

  },
  methods:{
    goPoliceNavigation(id){
      this.$router.push({path:'/policeNavigation?id='+ id})
    },
    goDetail(id){
      this.$router.push({path: '/policePlot?id='+ id })
    },
    goCampus(){
      this.$router.push({path:'/campus'})
    },
  },
}
</script>

<style scoped>
.common-bg{
  padding: 30px;
}
.home-main-div{
  margin-bottom: 30px;
}
.home-main-title{
  font-size: 40px;
  font-weight: bold;
  color: #1f66b6;
  text-align: left;
  position: relative;
  padding-left:50px;
  margin-bottom: 25px;
}
.home-main-title p{
  padding: 0;
  margin: 0;
}
.home-main-title::before{
  content: '';
  width: 4px;
  height: 100%;
  position: absolute;
  left: 20px;
  top: 0;
  background: #749dd6;
}
.home-main-title::after{
  content: '';
  width: 6px;
  height: 100%;
  position: absolute;
  left: 28px;
  top: 0;
  background: #2067b6;
}
.home-main-content{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home-item{
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  width: 49%;
  justify-content: flex-start;
  margin-bottom: 20px;
  align-items: center;
}
.home-item img{
  width: 57px;
  margin-left: 10px;
  flex-shrink:0 !important;
}
.home-item span{
  flex: 1;
  text-align: center;
}
</style>